import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faMapMarked, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { Fade } from "react-awesome-reveal"

const SocialLinks = () => (
  <Fade direction="up" duration={500} cascade>
    <div className="flex justify-between md:justify-center w-full gap-5 text-white text-md md:text-sm">
      <a
        href="https://www.instagram.com/royal_watan"
        target="_blank"
        className="hover:text-secondary transition-colors ease-in-out duration-150"
      >
        <FontAwesomeIcon icon={faInstagram} fixedWidth />
      </a>
      <a
        href="https://www.facebook.com/royalwatan"
        target="_blank"
        className="hover:text-secondary transition-colors ease-in-out duration-150"
      >
        <FontAwesomeIcon icon={faFacebookF} fixedWidth />
      </a>
      <a
        href="https://twitter.com/royal_watan"
        target="_blank"
        className="hover:text-secondary transition-colors ease-in-out duration-150"
      >
        <FontAwesomeIcon icon={faTwitter} fixedWidth />
      </a>
      <a href="https://maps.app.goo.gl/1rz68mWS9bABrdoz7" target="_blank" className="flex items-center justify-center hover:text-secondary transition-colors ease-in-out duration-150">
        <FontAwesomeIcon icon={faMapMarked} fixedWidth className="mr-2" />
        <span className="hidden md:block">
          602-604 PERSHORE ROAD, BIRMINGHAM B29 7HQ
        </span>
      </a>
      <div className="flex">
        <a
          href="tel:01214711713"
          className="flex items-center justify-center hover:text-secondary transition-colors ease-in-out duration-150"
        >
          <FontAwesomeIcon icon={faPhone} fixedWidth className="mr-2" />
          <span className="max-w-[90px] truncate">0121 471 1713</span>
        </a>
        <span className="text-grey mx-3">/</span>
        <a
          href="tel:07722788884"
          className="flex items-center justify-center hover:text-secondary transition-colors ease-in-out duration-150"
        >
          <span className="max-w-[49px] truncate">0772 278 8884</span>
        </a>
      </div>
    </div>
  </Fade>
)

export default SocialLinks
