import cx from 'classnames';
import * as React from 'react';
import { useContext } from 'react';

import SocialLinks from './social-links';

const ContactNav = () => {
  return (
    <div
      id="contact-nav-bar"
      className={cx(
        "w-full p-4 md:py-3 md:px-5 flex items-center justify-center z-20 transition-all ease-in-out duration-75 uppercase text-md"
      )}
    >
      <SocialLinks />
    </div>
  )
}

export default ContactNav
