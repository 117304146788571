import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Dialog from '@radix-ui/react-dialog';
import * as React from 'react';
import { useEffect, useState } from 'react';

function CookieModal() {
  const [isOpen, setIsOpen] = useState(false)

  function handleCloseModal() {
    localStorage.setItem("cookieAccepted", "true")
    setIsOpen(false)
  }

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted")
    setIsOpen(!isCookieAccepted)
  }, [])

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        {/* <Dialog.Overlay className="bg-white/40 data-[state=open]:animate-overlayShow fixed inset-0 z-30" /> */}
        <Dialog.Content className="overflow-y-auto flex flex-col md:flex-row items-center justify-center text-center md:text-left gap-1 data-[state=open]:animate-contentShow fixed bottom-[-50px] md:bottom-[0px] left-[50%] max-h-[85vh] w-[90vw] max-w-[700px] translate-x-[-50%] translate-y-[-50%] rounded-xl bg-white p-[25px] shadow-2xl focus:outline-none z-40 ">
          <div>
            <FontAwesomeIcon
              icon={faCookieBite}
              className="mr-2 text-5xl text-secondary"
            />
          </div>
          <div className="flex-1 grid gap-1">
            <Dialog.Title className="m-0 text-2xl flex flex-col">
              <h2>Cookies</h2>
            </Dialog.Title>
            <Dialog.Description className="text-lg leading-normal mb-3">
              This website uses cookies to ensure you get the best expierence.
              Visit our Privacy Policy page to find out more.
            </Dialog.Description>
          </div>
          <div className="grid gap-2">
            <button
              onClick={handleCloseModal}
              className="bg-secondary text-white text-lg px-4 py-1 rounded-md active:outline"
            >
              OK
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default CookieModal
