import React, { createContext, useContext, useState } from "react"
import { trackClick } from "../utils/track"

import { StoreContext } from "./store-context"

const MyContext = createContext()

const MenuModalProvider = ({ children }) => {
  const [product, setProduct] = useState(null)
  const [mealOptions, setMealOptions] = useState([])
  const [flavourOptions, setFlavourOptions] = useState([])
  const [extras, setExtras] = useState([])

  const { fetchCollectionWithProducts } = React.useContext(StoreContext)

  async function handleProductSelect(product, metafields) {
    console.log({ product, metafields })
    setProduct(product)
    trackClick(product?.title)
    if (product) {
      let mealOptionCollections = metafields?.find(
        m => m.key === "meal_options"
      )?.value
      let falvourOptionsList =
        metafields?.find(m => m.key === "flavour_options")?.value?.split(",") ||
        []
      if (mealOptionCollections) {
        mealOptionCollections = JSON.parse(mealOptionCollections)
        mealOptionCollections = mealOptionCollections.map(collectionId =>
          fetchCollectionWithProducts(collectionId)
        )
        await Promise.all(mealOptionCollections).then(res => {
          setMealOptions(res)
        })
      }
      setFlavourOptions(falvourOptionsList)
    } else {
      setFlavourOptions([])
      setMealOptions([])
      setExtras([])
    }
  }
  console.log({ product })
  return (
    <MyContext.Provider
      value={{
        product,
        mealOptions,
        flavourOptions,
        extras,
        handleProductSelect,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

const useMenuModalContext = () => {
  const context = useContext(MyContext)
  if (!context) {
    throw new Error("useMyContext must be used within a MyProvider")
  }
  return context
}

export { MyContext, MenuModalProvider, useMenuModalContext }
