import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { useState } from "react"
import { useWindowSize } from "usehooks-ts"
import { trackClick, trackAction } from "../utils/track"

const client = Client.buildClient(
  {
    domain: "royal-watan.myshopify.com",
    storefrontAccessToken: "a18aa05287ad9208f6247a8e56ab79d9",
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [cartOpen, setIsCartOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const size = useWindowSize()

  function toggleMobileNav() {
    setMobileNavOpen(prev => !prev)
  }

  function onCartOpenChange(isCartOpen) {
    trackClick("Toggle Cart")
    setIsCartOpen(isCartOpen)
  }

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }
    setCheckout(checkout)
  }

  async function createNewCheckout() {
    setLoading(true)
    const newCheckout = await client.checkout.create()
    setCheckoutItem(newCheckout)
    setLoading(false)
    trackAction("New Checkout", newCheckout.id)
  }

  React.useEffect(() => {
    // Define your own breakpoint values for mobile and tablet
    const mobileBreakpoint = 768 // Adjust this value as needed
    const tabletBreakpoint = 1024 // Adjust this value as needed
    setIsMobile(size.width < mobileBreakpoint)
    setIsTablet(size.width >= mobileBreakpoint && size.width < tabletBreakpoint)
  }, [size])

  React.useEffect(() => {
    // Open the cart when first item gets added
    if (!isMobile && !isTablet && checkout.lineItems.length === 1) {
      setIsCartOpen(true)
    }
  }, [checkout.lineItems, isMobile, isTablet])

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          setCheckoutItem(existingCheckout)
          trackAction("Restore Checkout", existingCheckoutID)
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
          trackAction("Restore Checkout Failed", existingCheckoutID)
        }
      } else {
        const newCheckout = await client.checkout.create()
        setCheckoutItem(newCheckout)
        trackAction("New Checkout", newCheckout.id)
      }
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (item, quantity) => {
    console.log({ item, quantity })
    setLoading(true)
    const checkoutID = checkout.id

    const primaryItem = {
      variantId: item.item.shopifyId,
      quantity,
      ...(item.options.length > 0 && {
        customAttributes: item.options.map(o => ({
          key: o?.type,
          value: o?.title,
        })),
      }),
    }
    return client.checkout.addLineItems(checkoutID, [primaryItem]).then(res => {
      setCheckout(res)
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000)
    })
  }

  const removeLineItem = lineItemID => {
    setLoading(true)
    return client.checkout
      .removeLineItems(checkout.id, [lineItemID])
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const fetchCollectionWithProducts = id => {
    return client.collection.fetchWithProducts(id)
  }

  const fetchCollection = id => {
    return client.collection.fetch(id)
  }

  const updateLineItem = (lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkout.id, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const createCustomer = async (email, password, firstName, lastName) => {
    const apiUrl = "https://royalwatan.myshopify.com/api/2022-07/graphql.json" // Replace with your store's URL
    const query = `
      mutation {
        customerCreate(input: {
          email: "${email}",
          password: "${password}",
          firstName: "${firstName}",
          lastName: "${lastName}"
        }) {
          customer {
            id
            email
          }
        }
      }
    `

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": "33ae598728799ac98184f38bf6e9f944",
      },
      body: JSON.stringify({ query }),
    })

    if (!response.ok) {
      throw new Error(`Failed to create customer: ${response.statusText}`)
    }

    const data = await response.json()
    return data.data.customerCreate.customer
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        fetchCollection,
        fetchCollectionWithProducts,
        toggleMobileNav,
        onCartOpenChange,
        createCustomer,
        createNewCheckout,
        cartOpen,
        mobileNavOpen,
        isMobile,
        isTablet,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
