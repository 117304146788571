import cx from "classnames"
import * as React from "react"
import { useContext } from "react"

import { StoreContext } from "../context/store-context"
import MenuList from "./menu-list"

const MobileNav = () => {
  const { toggleMobileNav } = useContext(StoreContext)
  return (
    <nav
      id="mobile-nav-bar"
      className={cx(
        "fixed top-[64px] w-full p-4 md:py-3 md:px-5 flex items-center z-20 transition-all ease-in-out duration-75 uppercase text-md bg-black shadow-lg lg:hidden text-white"
      )}
    >
      <MenuList onClick={toggleMobileNav} />
    </nav>
  )
}

export default MobileNav
