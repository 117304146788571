import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Dialog from "@radix-ui/react-dialog"
import * as RadioGroup from "@radix-ui/react-radio-group"
import cx from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { useEffect } from "react"
import { useState } from "react"
import * as Scroll from "react-scroll"
import { Element } from "react-scroll"
import { useMenuModalContext } from "../context/menu-modal-context"
import AddToCart from "./addToCart"
import Initials from "../images/svg/logo-initials.svg"
import { Fade } from "react-awesome-reveal"
import { trackAction, trackClick } from "../utils/track"

const defaultSelection = {
  item: null,
  options: [],
  quantity: 1,
  extras: [],
}

function Modal() {
  const [currentSelection, setCurrentSelection] = useState(defaultSelection)

  const { product, mealOptions, flavourOptions, handleProductSelect } =
    useMenuModalContext()

  const productHasSingleVarriant = product?.variants?.length === 1
  const hasMealDeal = product?.variants?.filter(v =>
    v.title?.toLowerCase()?.includes("meal")
  ).length

  const showMealOptions = currentSelection.item?.title
    ?.toLowerCase()
    ?.includes("meal")

  const totalSelectableOptions =
    mealOptions.length + (flavourOptions.length ? 1 : 0)

  const isStoreOffline = Boolean(process.env.GATSBY_STORE_OFFLINE === "true")
  const isInStoreOnly = Boolean(product?.tags?.find(t => t === "In Store Only"))

  function handleCloseModal() {
    handleProductSelect(null)
    setCurrentSelection(defaultSelection)
    trackClick("Add To Cart")
  }

  function handleSelectMealOptions(optionType, variant) {
    trackAction(optionType, variant)
    if (currentSelection.options.find(o => o.type === optionType)) {
      const updatedOptions = currentSelection.options.map(o => {
        if (o.type === optionType) {
          return { ...o, id: variant.id, title: variant.title }
        }
        return o
      })
      setCurrentSelection(prev => ({
        ...prev,
        options: updatedOptions,
      }))
    } else {
      setCurrentSelection(prev => ({
        ...prev,
        options: prev.options.concat({
          type: optionType,
          id: variant.id,
          title: variant.title,
          collectionId: variant.collectionId,
        }),
      }))
    }
  }

  function handleProductVariantSelect(variant) {
    trackClick(renderVariantTitle(variant))
    setCurrentSelection({
      ...defaultSelection,
      item: variant,
      options: [...currentSelection.options.filter(o => o.type === "Flavour")],
    })
  }

  function adjustQuantity(operation, operand) {
    if (operation === "add") {
      setCurrentSelection(prev => ({
        ...prev,
        quantity: prev.quantity + operand,
      }))
      trackClick(`Add Quantity`)
    } else if (operation === "subtract") {
      setCurrentSelection(prev => ({
        ...prev,
        quantity: prev.quantity - operand,
      }))
      trackClick(`Remove Quantity`)
    } else {
      return "Invalid operation. Please use 'add' or 'subtract'."
    }
  }

  useEffect(() => {
    if (productHasSingleVarriant) {
      // Auto select the single variant
      handleProductVariantSelect(product.variants[0])
    }
  }, [productHasSingleVarriant])

  useEffect(() => {
    // using the currently selected options find the options
    // that are current not selected
    const mainVariantSelect = !currentSelection.item && { id: "variant_select" }
    const nextOption = [...mealOptions, { id: "flavour_options" }].filter(
      mo => !currentSelection.options.find(o => o.collectionId === mo.id)
    )
    const scrollConfig = {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: "optionSelect",
    }
    if (mainVariantSelect) {
      Scroll.scroller.scrollTo(mainVariantSelect?.id, scrollConfig)
    }
    if (nextOption.length) {
      Scroll.scroller.scrollTo(nextOption[0]?.id, scrollConfig)
    }
  }, [currentSelection])

  function renderVariantTitle(variant) {
    if (variant.title === "Meal") {
      return "Make It A Meal"
    }
    if (hasMealDeal && variant.title === "Regular") {
      return `On It's Own`
    }
    return variant.title
  }

  function calcPriceDiff(price) {
    const basePrice = product?.priceRangeV2?.minVariantPrice?.amount
    return price - basePrice
  }

  const hasNoImageOrDesc =
    product &&
    !product.featuredImage &&
    !product.description?.length &&
    !product.collectionDesc?.legnth

  return (
    <Dialog.Root open={product} onInteractOutside={handleCloseModal}>
      <Dialog.Portal className="">
        <Dialog.Overlay className=" bg-[rgba(0,0,0,0.8)] data-[state=open]:animate-overlayShow fixed inset-0 z-30" />
        <Dialog.Content
          onEscapeKeyDown={handleCloseModal}
          onInteractOutside={handleCloseModal}
          className="flex flex-col z-30 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] h-[100dvh] md:h-auto md:max-h-[80dvh]  w-full md:w-[90vw] md:max-w-[450px] translate-x-[-50%] translate-y-[-50%] md:rounded-2xl bg-grey-dark shadow-lg focus:outline-none text-center overflow-y-auto"
        >
          <div className="bg-offwhite flex-1 flex flex-col overflow-y-auto">
            <Element
              id="optionSelect"
              className={cx("flex-col gap-2 flex-1 overflow-y-auto")}
              style={{
                position: "relative",
              }}
            >
              <div className="px-4 flex flex-col justify-center items-center pt-14">
                {product?.featuredImage && (
                  <div className="w-[250px] flex items-center justify-center flex-0">
                    <Fade duration={200}>
                      <GatsbyImage
                        image={
                          product.featuredImage.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={product?.title}
                      />
                    </Fade>
                  </div>
                )}
                <Dialog.Close
                  onClick={() => handleCloseModal()}
                  className="border absolute right-3 top-3 border-grey-light active:bg-grey-light text-grey rounded-full w-[35px] h-[35px] flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Dialog.Close>
                <Dialog.Title className="text-xl capitalize text-black font-bold flex gap-2 items-center">
                  {product?.title}
                </Dialog.Title>
                <p className="text-grey block text-md my-3">
                  {Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(product?.priceRangeV2?.minVariantPrice?.amount)}
                </p>
                <Dialog.Description className="leading-normal flex-1 my-3">
                  {product?.description?.length
                    ? product.description
                    : product?.collectionDesc}
                </Dialog.Description>
              </div>

              {flavourOptions.length > 0 && (
                <React.Fragment>
                  <Element name="flavour_options">
                    <div className="flex items-center justify-between p-2 border-b border-b-grey-light">
                      <h3 className="text-left text-lg text-primary">
                        Choose your flavour
                      </h3>
                      <div>
                        <span className="bg-red px-1 rounded text-xs text-white">
                          Required
                        </span>
                      </div>
                    </div>
                  </Element>
                  <RadioGroup.Root
                    className="flex flex-col divide-y-[1px] border-grey-light"
                    defaultValue="default"
                    aria-label="View density"
                  >
                    {flavourOptions.map(v => {
                      const optionTitle = v
                      const optionSelected = currentSelection.options.find(
                        o => o.title === v
                      )
                      return (
                        <div
                          key={v}
                          className={cx(
                            "flex items-center cursor-pointer text-black",
                            {
                              "bg-grey-light": optionSelected,
                              " hover:bg-grey-light": !optionSelected,
                            }
                          )}
                        >
                          <RadioGroup.Item
                            className="bg-white w-[25px] h-[22px] rounded-full border border-grey-light focus:shadow-black outline-none cursor-pointer ml-3"
                            value={v}
                            id={v}
                            onClick={() =>
                              handleSelectMealOptions("Flavour", {
                                id: v,
                                title: optionTitle,
                                collectionId: "flavour_options",
                              })
                            }
                          >
                            <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-primary" />
                          </RadioGroup.Item>
                          <label
                            className="flex justify-between w-full cursor-pointer text-sm p-3"
                            htmlFor={v}
                          >
                            <div className="ml-5">{optionTitle}</div>
                          </label>
                        </div>
                      )
                    })}
                  </RadioGroup.Root>
                </React.Fragment>
              )}

              {!productHasSingleVarriant && (
                <React.Fragment>
                  <Element name="variant_select">
                    <div className="flex items-center justify-between p-2 border-b border-b-grey-light">
                      <h3 className="text-left text-lg text-primary">
                        Choose one
                      </h3>
                      <div>
                        <span className="bg-red px-1 rounded text-xs text-white">
                          Required
                        </span>
                      </div>
                    </div>
                  </Element>
                  <RadioGroup.Root
                    className="flex flex-col divide-y-[1px] border-grey-light"
                    defaultValue="default"
                    aria-label="View density"
                  >
                    {product?.variants?.map(v => (
                      <div
                        key={v.shopifyId}
                        className={cx("flex items-center cursor-pointer", {
                          "bg-grey-light":
                            v.shopifyId === currentSelection?.item?.shopifyId,
                          " hover:bg-grey-light text-black":
                            v.shopifyId !== currentSelection?.item?.shopifyId,
                        })}
                      >
                        <RadioGroup.Item
                          className="bg-white w-[25px] h-[22px] rounded-full border border-grey-light focus:shadow-black outline-none cursor-pointer ml-3"
                          value={v.shopifyId}
                          id={v.shopifyId}
                          onClick={() => handleProductVariantSelect(v)}
                        >
                          <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-primary" />
                        </RadioGroup.Item>
                        <label
                          className="flex justify-between w-full cursor-pointer text-sm p-3"
                          htmlFor={v.shopifyId}
                        >
                          <div className="ml-5 text-left">
                            {renderVariantTitle(v)}
                            {v.title === "Meal" && (
                              <div className={cx("text-xs text-grey")}>
                                Fries & Drink
                              </div>
                            )}
                          </div>
                          {v.price !==
                            product.priceRangeV2.minVariantPrice.amount && (
                            <div>
                              +
                              {Intl.NumberFormat("en-GB", {
                                style: "currency",
                                currency: "GBP",
                              }).format(calcPriceDiff(v.price))}
                            </div>
                          )}
                        </label>
                      </div>
                    ))}
                  </RadioGroup.Root>
                </React.Fragment>
              )}

              {showMealOptions &&
                currentSelection.item &&
                mealOptions?.map(option => (
                  <React.Fragment key={option.id}>
                    <Element name={option.id}>
                      <div className="flex items-center justify-between p-2 border-b border-b-grey-light">
                        <h3 className="text-left text-lg text-primary">
                          {option.title}
                        </h3>
                        <div>
                          <span className="bg-red px-1 rounded text-xs text-white">
                            Required
                          </span>
                        </div>
                      </div>
                    </Element>
                    <RadioGroup.Root
                      className="flex flex-col divide-y-[1px] border-grey-light"
                      defaultValue="default"
                      aria-label="View density"
                    >
                      {option?.products.map(p =>
                        p.variants.map(v => {
                          const optionTitle = v.title.includes("Default Title")
                            ? p.title
                            : v.title
                          const optionSelected = currentSelection.options.find(
                            o => o.id === v.id
                          )
                          return (
                            <div
                              key={v.id}
                              className={cx(
                                "flex items-center cursor-pointer text-black",
                                {
                                  "bg-grey-light": optionSelected,
                                  " hover:bg-grey-light": !optionSelected,
                                }
                              )}
                            >
                              <RadioGroup.Item
                                className="bg-white w-[25px] h-[22px] rounded-full border border-grey-light focus:shadow-black outline-none cursor-pointer ml-3"
                                value={v.id}
                                id={v.id}
                                onClick={() =>
                                  handleSelectMealOptions(option.title, {
                                    ...v,
                                    title: optionTitle,
                                    collectionId: option.id,
                                  })
                                }
                              >
                                <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-primary" />
                              </RadioGroup.Item>
                              <label
                                className="flex justify-between w-full cursor-pointer text-sm p-3"
                                htmlFor={v.id}
                              >
                                <div className="ml-5">{optionTitle}</div>
                              </label>
                            </div>
                          )
                        })
                      )}
                    </RadioGroup.Root>
                  </React.Fragment>
                ))}
            </Element>
            {!isStoreOffline && !isInStoreOnly && (
              <div className="grid grid-cols-1 gap-5 shadow-2xl shadow-black px-4 pt-3 pb-10">
                {currentSelection?.item && (
                  <div className="grid grid-cols-3 max-w-fit m-auto">
                    <div className="">
                      <button
                        disabled={currentSelection.quantity === 1}
                        className="w-12 h-12 rounded-full outline-grey-light active:outline focus:outline active:bg-grey-light disabled:text-grey-light"
                        onClick={() => adjustQuantity("subtract", 1)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                    <div className="flex items-center justify-center text-2xl font-extrabold">
                      {currentSelection?.quantity}
                    </div>
                    <div className="">
                      <button
                        className="w-12 h-12 rounded-full outline-grey-light active:outline focus:outline active:bg-grey-light disabled:text-grey-light"
                        onClick={() => adjustQuantity("add", 1)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>
                )}
                <AddToCart
                  className={cx(
                    "p-4 flex-1 rounded-xl flex justify-between w-fill disabled:bg-grey text-white",
                    {
                      "bg-secondary": currentSelection?.item,
                    }
                  )}
                  item={currentSelection}
                  disabled={
                    !currentSelection?.item ||
                    (showMealOptions &&
                      currentSelection.options.length !==
                        totalSelectableOptions)
                  }
                  quantity={currentSelection.quantity}
                  onAddToCart={handleCloseModal}
                >
                  <div>Add to order</div>
                  <div className={cx({ hidden: !currentSelection.item })}>
                    {Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    }).format(
                      currentSelection.item?.price * currentSelection?.quantity
                    )}
                  </div>
                </AddToCart>
              </div>
            )}
            {isInStoreOnly && (
              <div className="bg-secondary p-4">
                This item is only available in store.
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Modal
