import {
  faChevronRight,
  faCircleNotch,
  faLock,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import * as React from "react"
import { Fade, Slide } from "react-awesome-reveal"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { StickyContainer } from "react-sticky"

import { StoreContext } from "../context/store-context"
import CheckoutCompleteModal from "./checkout-complete-modal"
import ContactNav from "./contact-nav"
import CookieModal from "./cookie-modal"
import Footer from "./footer"
import MobileNav from "./mobile-nav"
import Modal from "./modal"
import Nav from "./nav"

const Layout = ({ children }) => {
  const location = useLocation()
  const { loading, checkout, mobileNavOpen, cartOpen } =
    React.useContext(StoreContext)

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <StickyContainer>
        <div className="h-full relative">
          <Nav />
          <ContactNav />
          {mobileNavOpen && <MobileNav />}
          <main className="container-full 2xl:container 2xl:mx-auto my-0 2xl:my-20 2xl:rounded-lg flex-1 overflow-hidden ">
            <Modal />
            <CheckoutCompleteModal />
            <Fade>{children}</Fade>
          </main>
          <Footer />
          {location.pathname === "/" && checkout.lineItems.length > 0 && (
            <SkeletonTheme baseColor="#E0A228" highlightColor="#F9C766">
              <div
                id="sticky-checkout"
                className="p-5 lg:hidden sticky bottom-5 z-20 shadow-sm flex items-center justify-center text-white"
              >
                <Slide
                  direction={cartOpen ? "down" : "up"}
                  className="w-full flex"
                  delay={0}
                  duration={cartOpen ? 0 : 500}
                  reverse={cartOpen}
                >
                  <a
                    className="bg-secondary h-full rounded-xl p-4 w-full max-w-[600px] mx-auto text-white uppercase"
                    href={checkout.webUrl}
                    target="_self"
                  >
                    <div className="flex">
                      <div className="text-left flex-1">
                        <FontAwesomeIcon
                          icon={loading ? faCircleNotch : faLock}
                          spin={loading}
                          className="mr-2"
                          fixedWidth
                        />
                        Checkout
                      </div>
                      <div className="flex-1 flex items-center justify-end">
                        {loading ? (
                          <Skeleton width={80} />
                        ) : (
                          Intl.NumberFormat("en-GB", {
                            style: "currency",
                            currency: "GBP",
                          }).format(checkout.totalPriceV2?.amount)
                        )}

                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="ml-2"
                        />
                      </div>
                    </div>
                  </a>
                </Slide>
              </div>
            </SkeletonTheme>
          )}
        </div>
      </StickyContainer>
    </SkeletonTheme>
  )
}

export default Layout
