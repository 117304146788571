import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Form from "@radix-ui/react-form"
import { Link } from "gatsby"
import * as React from "react"
import { useState } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Logo from "../images/svg/logo.svg"

const socials = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/royal_watan",
    icon: faInstagram,
  },
  {
    title: "Facebook",
    link: "https://www.facebook.com/royalwatan",
    icon: faFacebookF,
  },
  {
    title: "Twitter",
    link: "https://twitter.com/royal_watan",
    icon: faTwitter,
  },
]
const information = [
  { title: "About Us", link: "/about" },
  { title: "Terms Of Use", link: "/terms" },
  { title: "Privacy Policy", link: "/privacy" },
]

const Footer = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)

  async function handleSubmit(event) {
    const data = Object.fromEntries(new FormData(event.currentTarget))
    event.preventDefault()
    setIsSubscribed(true)

    if (process.env.NODE_ENV === "development") {
      localStorage.setItem("userSubscribed", "true")
      setIsSubscribed(true)
      return
    }
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    }).catch(error => alert(error))
  }
  const renderSectionTitle = title => (
    <h3 className="text-xl uppercase text-black font-bold">{title}</h3>
  )
  return (
    <div className="bg-black flex flex-col items-center justify-center gap-10 h-full p-10 [&>div]">
      <div className="hidden lg:grid gap-4">
        <div>
          <Logo className="w-[200px] fill-white" />
        </div>
      </div>
      <div className="flex gap-5 text-white">
        <div>
          {/* {renderSectionTitle("Our Socials")} */}
          <ul className="text-lg flex justify-center gap-10">
            {socials.map((s, i) => (
              <li key={i}>
                <OutboundLink
                  href={s.link}
                  target="_blank"
                  className="inline-flex items-center py-2 gap-2 hover:text-secondary"
                >
                  <FontAwesomeIcon icon={s.icon} fixedWidth />
                  <div className="flex-1 hidden md:block">{s.title}</div>
                </OutboundLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-5 text-white">
        <div className="flex gap-5">
          <div className="px-10 py-5 text-center">
            <div className="font-medium uppercase mb-3">Weeknights</div>
            <div>TUES - THURS</div>
            <div className="text-sm decoration-dotted border-dotted border-b">17:00 to 00:00</div>
          </div>
          <div className="px-10 py-5 text-center">
            <div className="font-medium uppercase mb-3">Weekend</div>
            <div>FRI - SUN</div>
            <div className="text-sm decoration-dotted border-dotted border-b">17:00 to 00:00</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
